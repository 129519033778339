<template>
  <div>
     <header>
        <div style='width: 100%;'>
            <div style='width: 30%; text-align: center; display: inline-block; '>
                <h4>
                    <img src="https://cdn-static.brmja.com/storage/scrapped/6319866ea8712498504411.jpg"
                        style='width: 50%' alt="">
                    <br>
                    وزارة التعليم
                    <br>
                    <div id="school_name"></div>
                    </h4>
            </div>
            <div style='width: 30%; text-align: center; display: inline-block;'>
                <img src='https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg' style='width: 80%; margin-top: -70px'>
            </div>
            <div
                style='width: 30%; text-align: center; display: inline-block;  text-align: right; padding-top: 10px;'>
                <br>
                <h3>
                    العام الدراسي: <span id="r1"></span><br>
                    الفصل الدراسي: <span id="r2"></span><br>
                    الفصل: <span id="row_name"></span><br>
                    <span id="subject"></span>
                </h3>
            </div>
        </div>
    </header>
    <div class="content">
        <center>
            <div id="title"></div>
        </center>
        <table id="table">
            <tbody></tbody>
        </table>
        <h4>
            &nbsp;&nbsp;&nbsp;<span id="teacher"></span> مدير المدرسة: <span id="admin"></span> 
        </h4>
        <button class="excel" @click="excel()">تصدير الى إكسل</button>
    </div>
  </div>
</template>

<script>
export default {
    methods: {
        excel(){
            var tableToExcel = (function() {
            var uri = 'data:application/vnd.ms-excel;base64,'
                , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><?xml version="1.0" encoding="UTF-8" standalone="yes"?><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
                , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
                , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
            return function(table, name) {
                if (!table.nodeType) table = document.getElementById(table)
                var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML }
                window.location.href = uri + base64(format(template, ctx))
            }
            })()
            $("#table input").each(function(){
                if($(this).parent().find("span").length == 0){
                    $(this).parent().append(`<span class='dnone'>${$(this).val()}</span>`)
                    $(this).css('display', 'none')
                    var t = $(this);
                    setTimeout(() => {
                        t.css('display', 'block')
                        t.parent().find("span").remove()
                    }, 1000);
                }
            })
            tableToExcel('table', `كشف المتابعة لمادة ${decodeURIComponent(window.location.href.split("subject=")[1].split("&")[0])} لفصل ${decodeURIComponent(window.location.href.split("row_name=")[1].split("&")[0])}`)
        }
    },
    created(){


var naqar_get = function(t){
    return new Promise((resolveOuter) => {
        $("body").append(`<textarea class='get-local-storage' name='${t}'></textarea>`)
        var i111 = setInterval(function(){
            if($(".get-local-storage-value[name='"+t+"']").length){
                clearInterval(i111)
                resolveOuter($(".get-local-storage-value[name='"+t+"']").val())
            }
        })
    });
}
                var row_name = decodeURIComponent(window.location.href.split("row_name=")[1].split("&")[0])
                var subject = decodeURIComponent(window.location.href.split("subject=")[1].split("&")[0])
                    //
    if(window.location.href.includes("Bike")){
$("table").css("width", "100% !important")
            var index = 0
            naqar_get(`${window.location.href.split("type=")[1].split("&")[0]}_students_${localStorage.getItem('school_id')}`).then(function (e) {
                var e = {
                    madrasati_students: JSON.parse(e)
                }
                if (e.madrasati_students) {
                    e.madrasati_students.sort((a, b) => a.name.localeCompare(b.name))
            $('.content tbody').append(trfortoprows)
            $("#subject").html("المادة: " + decodeURIComponent(window.location.href.split("subject=")[1].split("&")[0]) + " &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;")
                $("#teacher").html("معلم المادة: " + decodeURIComponent(window.location.href.split("teacher=")[1].split("&")[0]) + " &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;")
         $("#row_name").html(row_name)
         $("#school_name").html( decodeURIComponent(window.location.href.split("school_name=")[1].split("&")[0]))
         $("#r1").html( decodeURIComponent(window.location.href.split("r1=")[1].split("&")[0]))
         $("#r2").html( decodeURIComponent(window.location.href.split("r2=")[1].split("&")[0]))
         $("#admin").html( decodeURIComponent(window.location.href.split("admin=")[1].split("&")[0]))
        setTimeout(() => {
            window.print()
        }, 1000);
        var rrr = "", trfady = ""
        for(var x = 1; x<=30; x++){
            rrr = rrr + "<td>"+x+ (x <10 ? '&nbsp;&nbsp;' : '') +"</td>"
            trfady = trfady + "<td></td>"
        }
        $("tbody").append(`
        <tr style="background:#ddd">
            <td>م</td>
            <td>اسم الطالب</td>
            ${rrr}
        </tr>
        `)
                    e.madrasati_students.forEach(student => {
                        if (student.row_name?.trim()?.includes(row_name?.trim())) {
                            if($("td:contains('"+student.name+"')").length == 0){
                                index = index + 1
                                $("tbody").append(`
                                <tr style="background:#fff; text-align: right">
                                    <td>${index}</td> <td  style="  border-top: 1px black solid;">${student.name}</td>
                                    ${trfady}
                                </tr>
                                `)
                            }
                        }
                    });
                }
            })
    }else{

            var trbiafniaapk = `<tr style="background:#ddd">
    <th rowspan="3" colspan="2">اسم الطالب</th>
    <th colspan="2">المهام الأدائية</th>
    <th colspan="2">المشاركة والتفاعل</th>
    <th colspan="2">الاختبار</th>
    <th rowspan="2">المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>واجبات</th>
    <th>بحوث مشروعات</th>
    <th>تطبيقات انشطة صفية</th>
    <th>مشاركة</th>
    <th>قصير</th>
    <th>تطبيقي</th>
 </tr>
 <tr style="background:#ddd">
    <th>15</th>
    <th>20</th>
    <th>20</th>
    <th>15</th>
    <th>10</th>
    <th>20</th>
    <th>100</th>
 </tr>`;

            var trbiafniafady = ` <td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;

            var rqmiardtr = ` <tr style="background:#ddd">
    <th rowspan="2" colspan="2">اسم الطالب</th>
    <th>واجبات</th>
    <th>بحوث مشاريع</th>
    <th>أنشطة ومشاركة</th>
    <th>تطبيقات عملية</th>
    <th>اختبار قصير</th>
    <th>المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>10</th>
    <th>10</th>
    <th>20</th>
    <th>40</th>
    <th>20</th>
    <th>100</th>
 </tr>`;

            var rqmiafady = `<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;

            var drasaatrowstop = ` <tr style="background:#ddd">
    <th rowspan="3" colspan="2">اسم الطالب</th>
    <th colspan="2">المهام الأدائية</th>
    <th colspan="2">المشاركة والتفاعل</th>
    <th colspan="2">الاختبار</th>
    <th rowspan="2">المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>واجبات</th>
    <th>بحوث مشروعات</th>
    <th>تطبيقات انشطة صفية</th>
    <th>مشاركة</th>
    <th>قصير</th>
    <th>نهائي</th>
 </tr>
 <tr style="background:#ddd">
    <th>15</th>
    <th>20</th>
    <th>20</th>
    <th>15</th>
    <th>10</th>
    <th>20</th>
    <th>100</th>
 </tr>`;
            var drasaatrowstopfady = `<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
           var osriarowst = `<tr style="background:#ddd">
    <th rowspan="3" colspan="2">اسم الطالب</th>
    <th colspan="2">المهام الأدائية</th>
    <th colspan="2">المشاركة والتفاعل</th>
    <th colspan="2">الاختبار</th>
    <th rowspan="2">المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>واجبات</th>
    <th>بحوث مشروعات</th>
    <th>تطبيقات انشطة صفية</th>
    <th>مشاركة</th>
    <th>قصير</th>
    <th>نهائي</th>
 </tr>
 <tr style="background:#ddd">
    <th>20</th>
    <th>20</th>
    <th>20</th>
    <th>20</th>
    <th>20</th>
    <th>--</th>
    <th>100</th>
 </tr>`;
           var osriarowstfady = `<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
           var loomtoptr = ` <tr style="background:#ddd">
    <th rowspan="2" colspan="2">اسم الطالب</th>
    <th>واجبات</th>
    <th>مهام أدائية</th>
    <th>مشاركة</th>
    <th>اختبار قصير</th>
    <th>اختبار نهائي</th>
    <th>المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>10</th>
    <th>20</th>
    <th>10</th>
    <th>20</th>
    <th>40</th>
    <th>100</th>
 </tr>`;
          var  loomtoptrfady = ` <td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
         var   nafstoptr = ` <tr style="background:#ddd">
    <th rowspan="2" colspan="2">اسم الطالب</th>
    <th>واجبات</th>
    <th>مهام أدائية</th>
    <th>مشاركة</th>
    <th>اختبار قصير</th>
    <th>اختبار نهائي</th>
    <th>المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>10</th>
    <th>20</th>
    <th>10</th>
    <th>20</th>
    <th>40</th>
    <th>100</th>
 </tr>`;
          var  nafstoptrfady = ` <td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
         var   wcantoptrs = ` <tr style="background:#ddd">
    <th rowspan="3" colspan="2">اسم الطالب</th>
    <th colspan="2">المهام الأدائية</th>
    <th colspan="2">المشاركة والتفاعل</th>
    <th colspan="2">الاختبار</th>
    <th rowspan="2">المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>واجبات</th>
    <th>بحوث مشروعات</th>
    <th>تطبيقات انشطة صفية</th>
    <th>مشاركة</th>
    <th>قصير</th>
    <th>تطبيقي</th>
 </tr>
 <tr style="background:#ddd">
    <th>10</th>
    <th>5</th>
    <th>10</th>
    <th>15</th>
    <th>20</th>
    <th>40</th>
    <th>100</th>
 </tr>`;
           var wcantoptrsfady = `<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
          var  tagwidtoptrd = ` <tr style="background:#ddd">
    <th rowspan="3" colspan="2">اسم الطالب</th>
    <th colspan="4">القرآن الكريم</th>
    <th colspan="4">التجويد</th>
    <th rowspan="2">المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>صحة القراءة</th>
    <th>الترتيل</th>
    <th>التجويد</th>
    <th>الحفظ</th>
    <th>واجبات</th>
    <th>تطبيقات</th>
    <th>اختبار قصير</th>
    <th>اختبار نهائي</th>
 </tr>
 <tr style="background:#ddd">
    <th>40</th>
    <th>5</th>
    <th>5</th>
    <th>20</th>
    <th>5</th>
    <th>5</th>
    <th>5</th>
    <th>15</th>
    <th>100</th>
 </tr>`;
          var  tagwidtoptrdfady = ` <td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
          var  islamiatoptrs = `<tr style="background:#ddd">
    <th rowspan="2" colspan="2">اسم الطالب</th>
    <th>واجبات</th>
    <th>مهام أدائية</th>
    <th>مشاركة</th>
    <th>اختبار قصير</th>
    <th>اختبار نهائي</th>
    <th>المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>10</th>
    <th>20</th>
    <th>10</th>
    <th>20</th>
    <th>40</th>
    <th>100</th>
 </tr>`;
          var  islamiatoptrsfady = ` <td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
         var   ryadiattoptrs = ` <tr style="background:#ddd">
    <th rowspan="2" colspan="2">اسم الطالب</th>
    <th>واجبات</th>
    <th>مهام أدائية</th>
    <th>مشاركة</th>
    <th>اختبار قصير</th>
    <th>اختبار نهائي</th>
    <th>المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>10</th>
    <th>20</th>
    <th>10</th>
    <th>20</th>
    <th>40</th>
    <th>100</th>
 </tr>`;
        var    ryadiattoptrsfady = `<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
         var   gmilatoptrf = ` <tr style="background:#ddd">
    <th rowspan="3" colspan="2">اسم الطالب</th>
    <th colspan="2">المهام الأدائية</th>
    <th colspan="2">المشاركة والتفاعل</th>
    <th colspan="2">الاختبار</th>
    <th rowspan="2">المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>واجبات</th>
    <th>بحوث مشروعات</th>
    <th>تطبيقات انشطة صفية</th>
    <th>مشاركة</th>
    <th>قصير</th>
    <th>نهائي</th>
 </tr>
 <tr style="background:#ddd">
    <th>5</th>
    <th>10</th>
    <th>15</th>
    <th>10</th>
    <th>20</th>
    <th>40</th>
    <th>100</th>
 </tr>`;
          var  gmilatoptrffady = `<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
         var   lo8atytoptrs = ` <tr style="background:#ddd">
    <th rowspan="3" colspan="2">اسم الطالب</th>
    <th colspan="2">المهام الأدائية</th>
    <th colspan="2">المشاركة والتفاعل</th>
    <th colspan="2">الاختبار</th>
    <th rowspan="2">المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>واجبات</th>
    <th>بحوث مشروعات</th>
    <th>تطبيقات انشطة صفية</th>
    <th>مشاركة</th>
    <th>قصير</th>
    <th>نهائي</th>
 </tr>
 <tr style="background:#ddd">
    <th>5</th>
    <th>10</th>
    <th>15</th>
    <th>10</th>
    <th>20</th>
    <th>40</th>
    <th>100</th>
 </tr>`;
        var    lo8atytoptrsfady = `  <td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;
        var    krimtoptrs = ` <tr style="background:#ddd">
    <th rowspan="2" colspan="2">اسم الطالب</th>
    <th style="width:12%">صحة القراءة</th>
    <th style="width:12%">الترتيل</th>
    <th style="width:12%">تطبيق التجويد</th>
    <th style="width:12%">الحفظ</th>
    <th style="width:12%">المجموع</th>
 </tr>
 <tr style="background:#ddd">
    <th>45</th>
    <th>10</th>
    <th>10</th>
    <th>35</th>
    <th>100</th>
 </tr>`;
        var    krimtoptrsfady = `<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>
<td><input style=" height: 14px; width: 30px; border: 0; text-align: center; "></td>`;


            if (subject.indexOf("فنية") !== -1) {
               var trfady = trbiafniafady;
               var trfortoprows = trbiafniaapk;
            } else if (subject.indexOf("رقمية") !== -1) {
               var trfady = rqmiafady;
               var trfortoprows = rqmiardtr;
            } else if (subject.indexOf("جتماعية") !== -1) {
               var trfady = drasaatrowstopfady;
               var trfortoprows = drasaatrowstop;
            } else if (subject.indexOf("سرية") !== -1) {
               var trfady = osriarowstfady;
               var trfortoprows = osriarowst;
            } else if (subject.indexOf("علوم") !== -1) {
               var trfady = loomtoptrfady;
               var trfortoprows = loomtoptr;
            } else if (subject.indexOf("نفس") !== -1) {
               var trfady = nafstoptrfady;
               var trfortoprows = nafstoptr;
            } else if ((subject.indexOf("We") !== -1) || (subject.indexOf("WE") !== -1) || (subject.indexOf("we") !== -1)) {
               var trfady = wcantoptrsfady;
               var trfortoprows = wcantoptrs;
            } else if (subject.indexOf("تجويد") !== -1) {
               var trfady = tagwidtoptrdfady;
               var trfortoprows = tagwidtoptrd;
            } else if (subject.indexOf("سلام") !== -1) {
               var trfady = islamiatoptrsfady;
               var trfortoprows = islamiatoptrs;
            } else if (subject.indexOf("رياض") !== -1) {
               var trfady = ryadiattoptrsfady;
               var trfortoprows = ryadiattoptrs;
            } else if (subject.indexOf("جميلة") !== -1) {
               var trfady = gmilatoptrffady;
               var trfortoprows = gmilatoptrf;
            } else if ((subject.indexOf('لغتي') !== -1) && (subject.indexOf('الجميلة') == -1)) {
               var trfady = lo8atytoptrsfady;
               var trfortoprows = lo8atytoptrs;
            } else if ((subject.indexOf('الكريم') !== -1) && (subject.indexOf('وتجويده') == -1)) {
               var trfady = krimtoptrsfady;
               var trfortoprows = krimtoptrs;
            } else {
               var trfady = ryadiattoptrsfady;
               var trfortoprows = ryadiattoptrs;
            }
            var index = 0
            naqar_get(`${window.location.href.split("type=")[1].split("&")[0]}_students_${localStorage.getItem('school_id')}`).then(function (e) {
                var e = {
                    madrasati_students: JSON.parse(e)
                }
                if (e.madrasati_students) {
                    e.madrasati_students.sort((a, b) => a.name.localeCompare(b.name))
            $('.content tbody').append(trfortoprows)
            $("#subject").html("المادة: " + decodeURIComponent(window.location.href.split("subject=")[1].split("&")[0]) + " &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;")
                $("#teacher").html("معلم المادة: " + decodeURIComponent(window.location.href.split("teacher=")[1].split("&")[0]) + " &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;")
         $("#row_name").html(row_name)
         $("#school_name").html( decodeURIComponent(window.location.href.split("school_name=")[1].split("&")[0]))
         $("#r1").html( decodeURIComponent(window.location.href.split("r1=")[1].split("&")[0]))
         $("#r2").html( decodeURIComponent(window.location.href.split("r2=")[1].split("&")[0]))
         $("#admin").html( decodeURIComponent(window.location.href.split("admin=")[1].split("&")[0]))
        setTimeout(() => {
            window.print()
        }, 1000);
                    e.madrasati_students.forEach(student => {
                        if (student.row_name?.trim()?.includes(row_name?.trim())) {
                            if($("td:contains('"+student.name+"')").length == 0){
                                index = index + 1
                                $("tbody").append(`
                                <tr style="background:#fff; text-align: right">
                                    <td>${index}</td> <td  style="  border-top: 1px black solid;">${student.name}</td>
                                    ${trfady}
                                </tr>
                                `)
                            }
                        }
                    });
                }
            })
            //
        setTimeout(() => {
            $("tr").each(function () {
                $(this).find('td').each(function () {
                    if ($(this).find("input").length && $(this).index() < 5) {
                        $(this).attr("style", `
                    `)
                        var h = $(this).html()
                        $(this).html(`
                    <style>
                    .child{
                        border: none !important;
                        border-left: 1px solid !important;
                    }
                    .child:last-child{
                        border: none !important;
                    }
                    </style>
                   <table style='border: none !important'>
                   <tr style='border: none !important; padding: 0px !important'>
                        <td class="child" style=' padding: 0px !important'>
                        ${h}
                        </td>
                        <td class="child" style=' padding: 0px !important'>
                        ${h}
                        </td>
                        <td class="child" style=' padding: 0px !important'>
                        ${h}
                        </td>
                        <td class="child" style=' padding: 0px !important'>
                        ${h}
                        </td>
                        <td class="child" style=' padding: 0px !important'>
                        ${h}
                        </td>
                    </tr>
                    </table>
                   `)
                    }
                })
            })
        }, 500);
        $(".h-100").removeClass("h-100")
    }
    }
}
</script>

<style>
body{
    background: #fff !important;
}
table,
td,
th {
    border: 1px solid;
}

table {
    width: 100%;
    border-collapse: collapse;
}

* {
    font-family: "myFirstFont" !important;
    font-weight: normal !important;
}
.print {
    position: absolute;
    top: 100px;
    left: 0px;
    z-index: 9999 !important;
}

.print2 {
    position: absolute;
    top: 140px;
    left: 0px;
    z-index: 9999 !important;
}
button {
    border: 2px solid #4CAF50;
    /* Green */
    padding: 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    width: 100px;
    background-color: #4CAF50;
    color: white;
}

button:hover {
    background-color: #ff05c9;
    /* Green */
    color: white;
} 
@media print {
    .print {
        display: none
    }
    .print2 {
        display: none
    }
    .excel{
        display: none;
    }
@page {
    margin: 0 !important;
}
}

@page {
    margin: 0 !important;
}
tr td{
page-break-inside: avoid;
white-space: nowrap;
}
html[dir=rtl] .content{
    margin-right: 0px !important;
    padding: 5px;
    padding-top: 0px !important;
    padding-right: 0px;
    padding-left: 0px;
}
</style>